<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->
      <h3 class="mb-1 text-primary">Bilal Abdurahman</h3>

      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-between">
        <!-- Search -->
        <search-input
          :placeholder="'Cari Tryout berdasrkan Nama...'"
          :onInput="onSearch"
        />
        <b-button variant="primary" @click="fetchData">
          <feather-icon class="mr-1" icon="RefreshCcwIcon" />
          <span class="text-nowrap">Refresh</span>
        </b-button>
      </div>
    </div>

    <!-- Filter By -->
    <div class="tw-inline-flex tw-mb-6">
      <custom-filter
        :data="filterList"
        :handleChange="handleChange"
        :filterValue="filterValue"
        :initialValue="initialValue"
      />
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- modal konfirmasi reset pengerjaan Tryout-->
      <b-modal
        id="reset-pengerjaan"
        cancel-variant="outline-secondary"
        ok-title="Reset"
        ok-variant="danger"
        cancel-title="Close"
        centered
        title="Reset Pengerjaan"
        @show="resetModal"
        @hidden="resetModal"
        @ok="deletePaketTryout(listPaketTryout[currentPaketIndex].id)"
      >
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <p>
                Apakah anda yakin ingin melakukan reset pengerjaan tryout ini?
              </p>
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="listPaketTryout"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.reset-pengerjaan
            variant="danger"
          >
            <!-- <feather-icon class="mr-1" icon="PlusCircleIcon" /> -->
            <span class="text-nowrap">Reset Pengerjaan</span>
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
// import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { downloadFile, useRouter } from "@core/utils/utils";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

// Fetch
import repository from "@repofactory";
const repoKategoriTryout = repository.get("kategoriTryoutRepository");
const repoPaketKategori = repository.get("paketKategoriRepository");
const repoPaketTryout = repository.get("paketTryoutRepository");
const repoHasilTryout = repository.get("HasilTryoutRepositories");
const repoJenis = repository.get("JenisRepository");

import CustomFilter from "@/@core/components/base/CustomFilter.vue";
import SearchInput from "@/@core/components/base/SearchInput.vue";

export default {
  components: {
    // ModalTambah,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    VBModal,
    BForm,
    BFormGroup,
    BCardText,
    BDropdown,
    flatPickr,
    BDropdownItem,
    ToastificationContent,
    BCardActions,
    draggable,
    BListGroupItem,
    vSelect,
    BOverlay,
    DatePicker,
    CustomFilter,
    SearchInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();

    const isLoading = ref(true);
    const modalLihat = ref(null);
    const showLihat = ref(false);
    const isSubmitting = ref(false);
    const isError = ref(false);
    const kategoryTryout = ref([]);
    const listPaketTryout = ref([]);
    const currentPaketIndex = ref(null);
    const form = ref({
      show: false,
      id: null,
      nama: "",
      kategori_tryout_id: null,
      jenis: "",
      harga: 0,
      pg: 0,
      show: true,
      tanggal_ujian: null,
      tanggal_penilaian: null,
      is_closed: false,
      paket_kategoris: [],
      label: null,
      harga_diskon: 0,
      is_active: true,
      jenis_id: null,
    });
    const listJenis = ref([]);
    const targetedId = ref(null);

    const listPaketKategori = ref([]);
    const listKategoriTryout = ref([]);
    const listJenisTryout = ref([
      {
        title: "PREMIUM",
        value: "PREMIUM",
      },
      {
        title: "GRATIS",
        value: "GRATIS",
      },
    ]);

    // Filter and Search
    const filterList = ref([
      {
        name: "Jenis",
        list: [],
      },
      {
        name: "Kategori",
        list: [],
      },
    ]);
    const initialValue = ref({
      "Mata Pelajaran": null,
      Kategori: null,
    });
    const filterValue = ref({ ...initialValue });
    const search = ref("");

    const tableColumns = [
      {
        key: "id",
        label: "ID",
        sortable: true,
      },
      // {
      //   key: 'kode',
      //   label: 'Kode Kategori',
      //   formatter: (value, key, item) => {
      //     return value.toUpperCase()
      //   },
      //   sortable: true
      // },
      {
        key: "nama",
        label: "Nama Paket",
        formatter: (value, key, item) => {
          return value.toUpperCase();
        },
        sortable: true,
      },
      {
        key: "jenis",
        label: "Jenis",
        formatter: (value, _, __) => {
          return value.toUpperCase();
        },
        sortable: true,
      },
      {
        key: "kategori_tryout.nama",
        label: "Kategori",
        formatter: (value, _, __) => {
          return value.toUpperCase();
        },
        sortable: true,
      },
      {
        key: "tanggal_dikerjakan",
        label: "Tanggal Dikerjakan",
        sortable: true,
      },
      {
        key: "score",
        label: "Score",
        sortable: true,
      },
      {
        key: "actions",
      },
    ];

    onMounted(async () => {
      fetchData();
      fetchJenis();
    });

    const handleOk = (e, method) => {
      e.preventDefault();
      switch (method) {
        case "store":
          submitPaketTryout(e, "store");
          break;

        case "edit":
          submitPaketTryout(e, "edit");
          break;

        case "destroy":
          deletePaketTryout(listPaketTryout.value[currentPaketIndex.value].id);
          break;

        default:
          showToast(
            "Error",
            `Method ${method} tidak tersedia.`,
            "warning",
            "AlertTriangleIcon"
          );
          break;
      }
    };

    const setTargetedId = (value) => {
      targetedId.value = value;
    };

    const setRowIndex = (item) => {
      currentPaketIndex.value = listPaketTryout.value.findIndex(
        (paket) => paket.id == item.id
      );
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const resetModal = () => {
      form.value = {
        id: null,
        show: false,
        nama: "",
        kategori_tryout_id: null,
        jenis: "",
        harga: 0,
        pg: 0,
        show: true,
        tanggal_ujian: null,
        tanggal_penilaian: null,
        is_closed: false,
        paket_kategoris: [],
        label: null,
        is_active: true,
        harga_diskon: 0,
        jenis_id: null,
      };
      setTargetedId(null);
    };

    const tambahPaketMapel = () => {
      form.value.paket_kategoris.push({
        durasi: 0,
        kategori: "",
        jumlah: 0,
      });
    };

    const removePaketMapel = (idx) => {
      const current = form.value.paket_kategoris;
      current.splice(idx, 1);
    };

    const setSelectedPaketMapel = (value, idx) => {
      const current = form.value.paket_kategoris[idx];

      current.kategori = value.kategori;
      current.jumlah = value.jumlah;
      current.durasi = value.durasi;
      current.id = value.id;
    };

    const fetchData = async () => {
      isLoading.value = true;
      fetchPaketKategoris();
      fetchKategoriTryout();

      await repoPaketTryout
        .get()
        .then(function (response) {
          isLoading.value = false;
          listPaketTryout.value = response.data.data;

          // listPaketTryout.value = response.data.data.filter((v) => {
          //   return (
          //     v.jenis == route.value.meta.tryout.jenis.toUpperCase() &&
          //     v.kategori_tryout.kode ==
          //     route.value.meta.tryout.kategori.toUpperCase()
          //   );
          // });
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const fetchJenis = async () => {
      try {
        const res = await repoJenis.get();
        listJenis.value = res.data.data;
        filterList.value[0].list = res.data.data.map((item) => ({
          label: item.nama.toUpperCase(),
          value: item.nama.toUpperCase(),
        }));
      } catch (error) {
        if (error.response) {
          showToast(
            "Error",
            error.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else if (error.request) {
          showToast(
            "Error",
            "Tidak bisa request data ke server",
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast("Error", error.message, "danger", "AlertTriangleIcon");
        }
      }
    };

    const showModal = (_, action) => {
      if (action === "store") {
        resetModal();
      } else {
        fetchDetail();
      }
    };

    const fetchDetail = async (id = targetedId.value) => {
      return await repoPaketTryout
        .show(id)
        .then(function (response) {
          form.value = {
            ...response.data.data,
            is_active: !!response.data.data.is_active,
            show: !!response.data.data.show,
          };
          form.value.paket_kategoris = response.data.data.paket_kategoris.map(
            (item) => ({
              ...item,
              jumlah: item.total_soal,
              durasi: item.total_durasi,
              kategori: item.kategori_soal.nama,
            })
          );
          // set peropery paket kategoris on fetch detail
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isError.value = true;
        });
    };

    const show = async (id) => {
      try {
        await fetchDetail(id);
        showLihat.value = true;
      } catch (error) {
        //
        if (error.response) {
          showToast(
            "Error",
            error.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else if (error.request) {
          showToast(
            "Error",
            "Tidak bisa request data ke server",
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast("Error", error.message, "danger", "AlertTriangleIcon");
        }
        isError.value = true;
      }
    };

    const fetchPaketKategoris = async () => {
      await repoPaketKategori
        .get()
        .then(function (response) {
          listPaketKategori.value = response.data.data.data.map((val) => {
            return {
              id: val.id,
              title: val.nama.toUpperCase(),
              jumlah: val.total_soal,
              durasi: parseFloat(val.total_durasi),
              kategori: val.kategori_soal.nama.toUpperCase(),
            };
          });

          filterList.value[1].list = response.data.data.data.map((item) => ({
            label: item.nama.toUpperCase(),
            value: item.nama.toUpperCase(),
          }));
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const fetchKategoriTryout = async () => {
      await repoKategoriTryout
        .get()
        .then(function (response) {
          listKategoriTryout.value = response.data.data.map((val) => {
            return {
              id: val.id,
              title: val.nama.toUpperCase(),
              kode: val.kode.toUpperCase(),
            };
          });
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const submitPaketTryout = async (e, action) => {
      isSubmitting.value = true;
      form.value.jenis = route.value.meta.tryout.jenis.toUpperCase();
      form.value.kategori_tryout_id = listKategoriTryout.value.find(
        (item) =>
          item.kode.toUpperCase() ==
          route.value.meta.tryout.kategori.toUpperCase()
      ).id;
      let data = {
        nama: form.value.nama,
        kategori_tryout_id: form.value.kategori_tryout_id,
        jenis: form.value.jenis,
        harga: form.value.harga,
        harga_diskon: form.value.harga_diskon,
        is_active: form.value.is_active,
        jenis_id: form.value.jenis_id,
        label: form.value.label,
        show: form.value.show,
        tanggal_ujian: form.value.tanggal_ujian,
        tanggal_penilaian: form.value.tanggal_penilaian,
        paket_kategoris: form.value.paket_kategoris,
        total_durasi: form.value.paket_kategoris.reduce(
          (n, { durasi }) => n + durasi,
          0
        ),
        total_soal: form.value.paket_kategoris.reduce(
          (n, { jumlah }) => n + jumlah,
          0
        ),
      };

      if (action === "store") {
        await repoPaketTryout
          .create(data)
          .then((response) => {
            listPaketTryout.value.push(response.data.data);

            showToast("Notifikasi", response.data.message, "primary");

            isSubmitting.value = false;
            resetModal();
            e.vueTarget.hide();
          })
          .catch((error) => {
            if (error.response) {
              showToast(
                "Error",
                error.response.data.message,
                "danger",
                "AlertTriangleIcon"
              );
            } else if (error.request) {
              showToast(
                "Error",
                "Tidak bisa request data ke server",
                "danger",
                "AlertTriangleIcon"
              );
            } else {
              showToast("Error", error.message, "danger", "AlertTriangleIcon");
            }
            isSubmitting.value = false;
          });
      } else {
        data = {
          ...data,
          paket_kategoris: data.paket_kategoris.map((item) => ({
            kategori: item.kategori,
            jumlah: item.jumlah,
            durasi: item.durasi,
            id: item.id,
          })),
          tanggal_penilaian: data.tanggal_penilaian.slice(0, -3),
          tanggal_ujian: data.tanggal_ujian.slice(0, -3),
        };
        await repoPaketTryout
          .update(targetedId.value, data)
          .then((response) => {
            fetchData();

            showToast("Notifikasi", response.data.message, "primary");

            isSubmitting.value = false;
            resetModal();
            e.vueTarget.hide();
          })
          .catch((error) => {
            if (error.response) {
              showToast(
                "Error",
                error.response.data.message,
                "danger",
                "AlertTriangleIcon"
              );
            } else if (error.request) {
              showToast(
                "Error",
                "Tidak bisa request data ke server",
                "danger",
                "AlertTriangleIcon"
              );
            } else {
              showToast("Error", error.message, "danger", "AlertTriangleIcon");
            }
            isSubmitting.value = false;
          });
      }
    };

    const updatekategoryTryout = async () => {
      isSubmitting.value = true;

      await repoKategoriTryout
        .update(
          {
            kode: form.value.kode,
            nama: form.value.nama,
          },
          form.value.id
        )
        .then((response) => {
          const currentKategori = kategoryTryout.value[currentPaketIndex.value];
          currentKategori.kode = response.data.data.kode;
          currentKategori.nama = response.data.data.nama;

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const deletePaketTryout = async (id) => {
      isSubmitting.value = true;

      await repoPaketTryout
        .delete(id)
        .then((response) => {
          listPaketTryout.value.splice(currentPaketIndex.value, 1);
          showToast("Notifikasi", response.data.message, "primary");
          resetModal();

          isSubmitting.value = false;
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    fetchData();

    const download = async (id, name) => {
      try {
        const res = await repoHasilTryout.download(id);
        await downloadFile(res.data, name);
      } catch (error) {
        if (error.response) {
          showToast(
            "Error",
            error.response.data.message,
            "danger",
            "AlertTriangleIcon"
          );
        } else if (error.request) {
          showToast(
            "Error",
            "Tidak bisa request data ke server",
            "danger",
            "AlertTriangleIcon"
          );
        } else {
          showToast("Error", error.message, "danger", "AlertTriangleIcon");
        }
      }
    };

    const handleChange = (value) => {
      filterValue.value = { ...value };
    };

    const onSearch = (e) => {
      search.value = e.currentTarget.value;
    };

    return {
      isError,
      isLoading,
      isSubmitting,
      kategoryTryout,
      tableColumns,
      form,
      listPaketKategori,
      listKategoriTryout,
      listJenisTryout,
      listPaketTryout,
      currentPaketIndex,
      listJenis,
      targetedId,
      filterList,
      initialValue,
      filterValue,
      search,
      // methods
      setRowIndex,
      fetchData,
      resetModal,
      submitPaketTryout,
      updatekategoryTryout,
      deletePaketTryout,
      tambahPaketMapel,
      setSelectedPaketMapel,
      removePaketMapel,
      handleOk,
      show,
      modalLihat,
      showLihat,
      download,
      fetchJenis,
      setTargetedId,
      fetchDetail,
      showModal,
      handleChange,
      onSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
